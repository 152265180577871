import React from 'react';

import { Box } from '@mui/material';

import BackgroundAnimation from 'components/common/Balls';
import { PortfolioSection } from 'components/portfolio/PortfolioSection';

import MetaAdsAvatarImg from 'assets/portfolio/projects/metaAds/metaAdsAvatar.png';
import MetaAdsCard_1_Img from 'assets/portfolio/projects/metaAds/metaAdsCard_1.png';
import MetaAdsCard_2_Img from 'assets/portfolio/projects/metaAds/metaAdsCard_2.png';
import MetaAdsCard_3_Img from 'assets/portfolio/projects/metaAds/metaAdsCard_3.png';
import MetaAdsCard_4_Img from 'assets/portfolio/projects/metaAds/metaAdsCard_4.png';
import TixnetAvatarImg from 'assets/portfolio/projects/tixnet/tixnetAvatar.png';
import TixnetCard_1_Img from 'assets/portfolio/projects/tixnet/tixnetCard_1.png';
import TixnetCard_2_Img from 'assets/portfolio/projects/tixnet/tixnetCard_2.png';
import TixnetCard_3_Img from 'assets/portfolio/projects/tixnet/tixnetCard_3.png';
import TixnetCard_4_Img from 'assets/portfolio/projects/tixnet/tixnetCard_4.png';
import ShopiviewAvatarImg from 'assets/portfolio/projects/videoReview/videoReviewAvatar.png';
import Shopiview_1_Img from 'assets/portfolio/projects/videoReview/videoReviewCard_1.png';
import Shopiview_2_Img from 'assets/portfolio/projects/videoReview/videoReviewCard_2.png';
import Shopiview_3_Img from 'assets/portfolio/projects/videoReview/videoReviewCard_3.png';
import Shopiview_4_Img from 'assets/portfolio/projects/videoReview/videoReviewCard_4.png';
import { useMediaSize } from 'hooks/useDeviceDetector';

export interface PortfolioProject {
  title: string;
  description: string;
  section: {
    avatar: string;
    fullName: string;
    sectionDescription: string;
    images: string[];
    link?: string;
    linkedin?: string;
  };
}

export const PORTFOLIO_PROJECTS = [
  {
    title: 'Tixnet',
    description:
      'Tixnet, the Ultimate Primary Ticketing Solution for Event Organizers. With Tixnet, you can sell, manage, and organize your events to optimize your sales and profit. Tixnet stands out as the first free global marketplace for tickets, offering a unique multilingual, multicurrency platform tailored to local users. Experience the convenience and efficiency of Tixnet for all your ticketing needs.',
    section: {
      avatar: TixnetAvatarImg,
      fullName: 'CEO TixNet Atle Barlaup',
      sectionDescription:
        "We have been working with Clever Wallet for several months. We admire and appreciate their professionalism and excellent service. Their platform provides us with all the tools we need to efficiently manage our finances, process payments, and invoice our clients. We appreciate their flexible approach to integrating with various payment systems, which allows us to offer convenient and secure ways to pay for tickets. Clever Wallet provides analytical and reporting capabilities that help us understand our customers' needs. This allows us to make informed decisions to optimize our business. We recommend their services to anyone looking for a trusted financial technology partner.",
      images: [TixnetCard_1_Img, TixnetCard_2_Img, TixnetCard_3_Img, TixnetCard_4_Img],
      link: 'https://staging-tixnet.eastrelay.com/ ',
      linkedin: 'https://www.linkedin.com/in/atle-barlaup-4b7ba416/',
    },
  },

  {
    title: 'Meta Ads',
    description:
      'Meta Ads is an advanced advertising platform designed for metaverses, immersive worlds, and augmented, virtual, and mixed reality environments. It focuses on performance marketing, analytics, and precise targeting, providing comprehensive solutions for reaching audiences in the rapidly evolving landscape of AR, VR, and MR experiences.',
    section: {
      avatar: MetaAdsAvatarImg,
      fullName: 'CEO Meta Ads, Yaroslav Volovoj',
      sectionDescription:
        'We successfully integrated Clever Wallet into our product, creating a payment and distribution system that accommodates both fiat and cryptocurrency transactions. Their solutions consistently demonstrate modernity and security, making them highly recommended partners.',
      images: [MetaAdsCard_1_Img, MetaAdsCard_2_Img, MetaAdsCard_3_Img, MetaAdsCard_4_Img],
      link: 'https://metaads.team/ ',
      linkedin: 'https://www.linkedin.com/in/yaroslavvolovoj/',
    },
  },

  {
    title: 'Shopiview',
    description:
      'Shopiview service provides a unique opportunity for users to leave paid reviews of products on various marketplaces. The platform is designed with the idea that every opinion has value, and strives to create a simple and effective system of rewarding useful reviews. Companies in turn gain access to quality and unbiased reviews, and users have the opportunity to earn extra income for their participation. Shopiview creates a fair and transparent environment for sharing opinions where both sides win.',
    section: {
      avatar: ShopiviewAvatarImg,
      fullName: 'Pavel Volkov Shopiview founder',
      sectionDescription:
        'We made a strategic decision not to spend time developing our own ledger and functionality for paying for our services, and instead used the Clever Wallet solution. This allowed us to significantly reduce our development time and promptly enter the market. Thanks to Clever Wallet, we can effectively manage the payment for services and the distribution of cash rewards among the users of our system, ensuring the reliability and security of all transactions.',
      images: [Shopiview_1_Img, Shopiview_2_Img, Shopiview_3_Img, Shopiview_4_Img],
      link: '',
      linkedin: '',
    },
  },
];

export const PortfolioProjects = () => {
  const {isPhone} = useMediaSize()
  return (
    <Box
      sx={{
        maxWidth: '100%',
        position: 'relative',
      }}
    >
      <BackgroundAnimation speed={isPhone ? 100 : 150} blur={isPhone ? 90 : 190} diameter={isPhone ? 250 : 500}  countOfBalls={isPhone ? 5 : 7} zIndex={-1} />
      {PORTFOLIO_PROJECTS.map((project, ind) => (
        <PortfolioSection key={project.title + ind} ind={ind} project={project} />
      ))}
    </Box>
  );
};
