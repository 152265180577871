import { backgroundGradient } from 'consts/style';
import { FC, ReactNode } from 'react';
import { theme } from 'theme';
import { scrollHandler } from 'utils/scrollHandler';

import { Box, Typography } from '@mui/material';

import Button from 'components/common/Button';
import ContentBox from 'components/common/ContentBox';
import {
  advantagesStartupsInfo,
  advantagesEnterprisesInfo,
} from 'components/serve/Advantages/consts';

import { Animation } from '../Animation/Animation';

interface AdvantageInfo {
  img: ReactNode;
  text: string;
  description?: string;
}

interface Props {
  isStartupsAdvantages?: boolean;
}

const Advantages: FC<Props> = ({ isStartupsAdvantages }) => {
  const advantagesInfo = isStartupsAdvantages ? advantagesStartupsInfo : advantagesEnterprisesInfo;

  return (
    <Box
      sx={{
        position: 'relative',
        backgroundImage: backgroundGradient,
        backgroundBlendMode: 'multiply, normal',
      }}
    >
      <ContentBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          padding: {
            xs: '72px 16px 72px 16px',
            sm: '72px 20px 72px 20px',
            md: '100px 24px 100px 24px',
          },
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '24px', sm: '32px', md: '40px' },
            fontWeight: { xs: 700, md: 700 },
            lineHeight: { xs: '33.6px', sm: '41.6px', md: '48px' },
            textAlign: 'center',
            color: '#FFFFFF',
          }}
        >
          Advantages
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: '24px',
            mt: '56px',
          }}
        >
          {advantagesInfo.map((advantage: AdvantageInfo) => (
            <Animation
              key={advantage.text}
              text={advantage.text}
              img={advantage.img}
              description={advantage.description}
            />
          ))}
        </Box>

        {isStartupsAdvantages && (
          <Button
            variant="contained"
            onClick={(e) => {
              scrollHandler(e, 'contact');
            }}
            sx={{
              width: '206px',
              borderRadius: '15px',
              backgroundColor: theme.palette.background.default,
              border: `1px ${theme.palette.background.default} solid`,
              padding: '12px 20px 12px 20px',
              boxSizing: 'border-box',
              '&:hover': { backgroundColor: theme.palette.grey[100] },
              mt: 7,
            }}
          >
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'center',
                color: theme.palette.primary.main,
              }}
            >
              {'Let`s start >'}
            </Typography>
          </Button>
        )}
      </ContentBox>
    </Box>
  );
};

export default Advantages;
