import React from 'react';

import { Box, Typography } from '@mui/material';

import { FAQAccordion } from 'components/home/faq/FaqAccordion/FaqAccordion';

interface IAccordionContent {
  question: string;
  answers: string[];
}

const ACCORDION_CONTENT: IAccordionContent[] = [
  {
    question: 'What is Clever Wallet?',
    answers: [
      'Clever Wallet is a platform that provides software development kits (SDKs) and APIs for creating financial applications and services. It includes multi-currency wallets, ledger, financial transaction processing and analytics, and supports a variety of transactions including deposits, payments, transfers, and withdrawals. Clever Wallet also offers customizable transactions such as escrow and billing, as well as integration capabilities for external payment systems and the SSO KeyCloak authorization service. The system provides strict role controls for enhanced security.',
    ],
  },
  {
    question: 'How can I get started?',
    answers: [
      'To get started with Clever Wallet, simply reach out to our team and we will guide you through the process. We provide fintech software development, e-wallet development, application development for P2P payments, and dedicated development teams to cater to your specific needs.',
    ],
  },
  {
    question: 'What industries do you serve?',
    answers: [
      'We provide our services to startups, enterprises, and developers across various industries. Whether you are a small startup or a large enterprise, we have the expertise to meet your requirements and deliver high-quality solutions.',
    ],
  },
  {
    question: 'How can I contact you?',
    answers: [
      'You can use any means of contacting us available in the "Contact us" section. We\'d be happy to help you',
    ],
  },
  {
    question: 'Still have questions?',
    answers: ['Contact us'],
  },
];
export const HomeFAQ = () => {
  return (
    <Box
      sx={{
        background: '#F9FAFB',
        minHeight: { xs: '730px', sm: '701px', md: '777px' },
        padding: {
          xs: '72px 16px 72px 16px',
          sm: '72px 20px 72px 20px',
          md: '100px 24px 100px 24px',
        },
        boxSizing: 'border-box',
      }}
      id="faq"
    >
      <Box
        sx={{
          minHeight: '577px',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '1307px',
          margin: 'auto',
          position: 'relative',
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: { xs: '30px', sm: '32px', md: '52px' },
              fontWeight: { xs: 700, md: 800 },
              lineHeight: { xs: '36px', sm: '41.6px', md: '62.4px' },
              textAlign: 'center',
            }}
          >
            FAQs
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '16px', sm: '18px' },
              fontWeight: { xs: 400 },
              lineHeight: { xs: '24px', sm: '27px' },
              textAlign: { xs: 'left', sm: 'center' },
              marginTop: '8px',
            }}
          >
            Find answers to common questions about Clever Wallet`s offerings
          </Typography>
        </Box>
        <Box sx={{ mt: '56px' }}></Box>
        {ACCORDION_CONTENT.map((item, ind) => (
          <FAQAccordion key={ind} question={item.question} answers={item.answers} />
        ))}
      </Box>
    </Box>
  );
};
