import { gradientHeaderBoxContent } from 'consts/content';
import React from 'react';

import { Box } from '@mui/material';

import BackgroundAnimation from 'components/common/Balls';
import { GradientHeaderBox } from 'components/common/GradientHeaderBox';
import Advantages from 'components/serve/Advantages';
import Enterprises from 'components/serve/Enterprises';
import Methodologies from 'components/serve/Methodologies';
import Startups from 'components/serve/Startups';
import { useMediaSize } from '../../hooks/useDeviceDetector';

export const ServePage = () => {
  const {isPhone} = useMediaSize()
  return (
    <Box>
      <GradientHeaderBox content={gradientHeaderBoxContent.serve} />
      <Box sx={{ position: 'relative', zIndex: 3 }}>
        <BackgroundAnimation speed={isPhone ? 100 : 150} blur={isPhone ? 90 : 190} diameter={isPhone ? 250 : 500} countOfBalls={isPhone ? 4 : 5} zIndex={-1} />
        <Startups />
        <Methodologies isStartupsMethodologies />
      </Box>

      <Advantages isStartupsAdvantages />
      <Box sx={{ position: 'relative' }}>
        <BackgroundAnimation speed={isPhone ? 100 : 150} blur={isPhone ? 90 : 190} diameter={isPhone ? 250 : 500} countOfBalls={isPhone ? 4 : 5}   zIndex={-1} />
        <Enterprises />
        <Methodologies />
      </Box>
      <Advantages />
    </Box>
  );
};
