import {
  FirstGradientBox,
  SecondGradientBox,
  ThirdGradientBox,
} from 'components/gradient-boxes/ForFeaturePage';

import FirstImg from 'assets/features/payment/firstImg.png';
import SecondImg from 'assets/features/payment/secondImg.png';
import ThirdImg from 'assets/features/payment/thirdImg.png';
import TriangleDownLeft from 'assets/features/triangles/triangleDownLeft.png';
import TriangleDownRight from 'assets/features/triangles/triangleDownRight.png';
import TriagleUpRight from 'assets/features/triangles/triangleUpRight.png';

export const headerInfoForFeaturesPages = {
  info: 'Pre-built integrations with Stripe, PayPal, Wise, and others, providing flexibility and the ability to process transactions in different currencies, customized to customer requirements',
  title: 'Payment System Integrations',
  details: 'Select the suitable payment system',
};

export const contentInfoForFeaturesPages = [
  {
    title: 'Manage Payment Gateways',
    description: [
      'Tailor gateway settings to meet business needs, with environment variables for seamless integration and management of payment services.',
    ],
    image: FirstImg,
    imageWidth: { xs: '310px', sm: '583px', md: '601px' },
    imageHeight: { xs: '223px', sm: '419px', md: '440px' },
    gradient: (
      <>
        <FirstGradientBox />
        <SecondGradientBox />
      </>
    ),
    triangle: TriagleUpRight,
  },
  {
    title: 'Support for Multiple Currencies',
    description: [
      'Facilitates transactions in major fiat currencies like USD and EUR, along with the flexibility to manage currency settings through integrated systems like Stripe.',
    ],
    image: SecondImg,
    imageWidth: { xs: '310px', sm: '584px', md: '621px' },
    imageHeight: { xs: '237px', sm: '446px', md: '476px' },
    triangle: TriangleDownLeft,
  },
  {
    title: 'Secure and Scalable',
    description: [
      'Each service within the platform is configurable for optimal performance, ensuring secure and efficient transaction processing.',
    ],
    image: ThirdImg,
    imageWidth: { xs: '310px', sm: '584px', md: '554px' },
    imageHeight: { xs: '247px', sm: '467px', md: '432px' },
    gradient: <ThirdGradientBox />,
    triangle: TriangleDownRight,
  },
];
