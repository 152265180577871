import React from 'react';

import { Box, Link, Typography } from '@mui/material';

import FifthImg from 'assets/home/about/fifthImg.png';
import FirstImg from 'assets/home/about/firstImg.png';
import FourthImg from 'assets/home/about/fourthImg.png';
import SecondImg from 'assets/home/about/secondImg.png';
import ThirdImg from 'assets/home/about/thirdImg.png';

export const HomeAbout = () => {
  return (
    <Box
      className="home_about_section"
      sx={{
        position: 'relative',
        overflow: 'hidden',
        padding: {
          xs: '72px 16px 72px 16px',
          sm: '72px 20px 72px 20px',
          md: '100px 24px 100px 24px',
        },
        boxSizing: 'border-box',
        zIndex: 2,
      }}
      id="about"
    >
      <Box
        sx={{
          maxWidth: '1312px',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: 'auto',
          position: 'relative',
          gap: '32px',
        }}
      >
        {/*<Box*/}
        {/*  sx={{*/}
        {/*    position: 'absolute',*/}
        {/*    zIndex: '1',*/}
        {/*    width: '1098px',*/}
        {/*    height: '1098px',*/}
        {/*    top: '-300.87px',*/}
        {/*    right: '-900px',*/}
        {/*    gap: '0px',*/}
        {/*    opacity: '0px',*/}
        {/*    angle: '-90 deg',*/}
        {/*    borderRadius: '50%',*/}
        {/*    background:*/}
        {/*      'radial-gradient(circle,rgba(254, 124, 82, 0.45) 10% , rgba(254, 124, 82, 0) 45%)',*/}
        {/*  }}*/}
        {/*/>*/}
        {/*<Box*/}
        {/*  sx={{*/}
        {/*    position: 'absolute',*/}
        {/*    zIndex: '-9',*/}
        {/*    width: '1098px',*/}
        {/*    height: '1098px',*/}
        {/*    top: '-200.87px',*/}
        {/*    right: '-600px',*/}
        {/*    gap: '0px',*/}
        {/*    opacity: '0px',*/}
        {/*    angle: '-90 deg',*/}
        {/*    borderRadius: '50%',*/}
        {/*    background:*/}
        {/*      'radial-gradient(circle,rgba(200, 113, 253, 0.5) 10% , rgba(200, 113, 253, 0)50%)',*/}
        {/*  }}*/}
        {/*/>*/}
        <Box
          sx={{
            minHeight: { xs: '', sm: '', md: '605px' },
            width: { xs: '100%', md: '58%' },
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '30px', sm: '32px', md: '52px' },
              fontWeight: { xs: 700, md: 800 },
              lineHeight: { xs: '36px', sm: '41.6px', md: '62,4px' },
              textAlign: { xs: 'center', sm: 'left' },
            }}
          >
            About us
          </Typography>
          <Box
            sx={{
              minHeight: { xs: '', sm: '', md: '433px' },
              maxWidth: { xs: '100%', md: '710px' },
              mt: '56px',
            }}
          >
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '21px',
                textAlign: 'left',
              }}
            >
              Our experts have something very specific in common - a passion for learning. To be one
              of our experts, you love to learn, you are on the cutting edge and stay there, you
              learn new things, you are a true expert - a pro.
              <br />
              <br />
              Our experts have been amazing clients in the United States and throughout Europe for
              more than 15 years. We bring to our work the discipline of process and risk
              management.
              <br />
              <br />
              Our relationships with hosting providers world-wide equip us to not only build the
              solution for you, but then to guide and support you in deployment, business practice
              integration and on-going customer service and system support.
              <br />
              <br />
              The secret to our experts’ success is the unity and cohesiveness of our team. The
              collective experience of our 30 some odd experts in every discipline within the
              software development process comes to bear to ensure your project is a success.
            </Typography>
            <Link
              style={{ borderBottom: 'none', display: 'inline-block', marginTop: '20px' }}
              href="https://wecandevelopit.com"
              sx={{
                fontSize: '20px',
                fontWeight: 600,
                lineHeight: '30px',
                textAlign: 'left',
                marginTop: 5,
              }}
              target="_blank"
            >
              wecandevelopit.com
            </Link>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          sx={{
            minHeight: { xs: '494px', sm: '781px', md: '791.84px' },
            width: { xs: '100%', sm: '80%', md: '42%' },
          }}
        >
          <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" alignItems="end" gap={2}>
              <Box
                className="home_about_image"
                component="img"
                height="auto"
                width="67%"
                alt="About us"
                src={FirstImg}
              />
              <Box
                className="home_about_image"
                component="img"
                height="auto"
                width="30%"
                alt="About us"
                src={SecondImg}
              />
            </Box>
            <Box
              className="home_about_image"
              component="img"
              height="auto"
              width="100%"
              alt="About us"
              src={ThirdImg}
            />
            <Box display="flex" alignItems="start" gap={2}>
              <Box
                className="home_about_image"
                component="img"
                height="auto"
                width="30%"
                alt="About us"
                src={FourthImg}
              />
              <Box
                className="home_about_image"
                component="img"
                height="auto"
                width="67%"
                alt="About us"
                src={FifthImg}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
