import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { scrollHandler } from 'utils/scrollHandler';

import { Box, Typography, useTheme } from '@mui/material';

import { ReactComponent as RightArrow } from 'assets/home/mobile/mobileRightArrow.svg';
import { ReactComponent as TopArrow } from 'assets/home/mobile/mobileTopArrow.svg';

interface IProps {
  title: string;
  description: string;
  images: string[];
  setCurrentImages: React.Dispatch<React.SetStateAction<string[]>>;
  setKeySwiper?: React.Dispatch<React.SetStateAction<number>>;
  tab?: string;
}

const MobileBlock: FC<IProps> = ({
  title,
  description,
  images,
  setCurrentImages,
  // setKeySwiper,
  tab,
}) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        maxWidth: { xs: '343px', sm: '796.8px' },
        minHeight: { xs: '187px', sm: '138px' },
        minWidth: { xs: '311px' },
        borderRadius: '15px',
        border: '1px solid white',
        padding: { xs: '12px', sm: '12px 24px', md: '24px' },
        boxSizing: 'border-box',
        background: 'rgba(255, 255, 255, 0.2)',
        cursor: 'pointer',
        transitionDuration: '0.4s',
        '&:hover': { background: theme.palette.primary.main },
      }}
      onMouseEnter={() => {
        setIsHover(true);
        // setKeySwiper((prev) => prev + 1);
        setCurrentImages([...images]);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={(e) => {
        tab ? navigate(`${tab}`) : scrollHandler(e, 'demo');
      }}
    >
      <Box
        sx={{
          maxWidth: '100%',
          minHeight: { xs: 0, sm: '34px' },
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '18', md: '24px' },
            fontWeight: { xs: 700 },
            lineHeight: { xs: '25.2px', md: '33.6px' },
            textAlign: 'left',
            color: 'rgba(255, 255, 255, 1)',
          }}
        >
          {title}
        </Typography>
        {isHover ? <TopArrow /> : <RightArrow />}
      </Box>
      <Typography
        sx={{
          fontSize: { xs: '14px', sm: '12px', md: '16px' },
          fontWeight: { xs: 400 },
          lineHeight: { xs: '21px', sm: '18px', md: '24px' },
          textAlign: 'left',
          marginTop: '8px',
          color: 'rgba(255, 255, 255, 1)',
        }}
      >
        {description}
      </Typography>
    </Box>
  );
};

export default MobileBlock;
