import {
  FifthGradientBox,
  FirstGradientBox,
  FourthGradientBox,
  SecondGradientBox,
  SixthGradientBox,
  ThirdGradientBox,
} from 'components/gradient-boxes/ForFeaturePage';

import FifthImg from 'assets/features/invoicing/fifthImg.png';
import FirstImg from 'assets/features/invoicing/firstImg.png';
import FourthImg from 'assets/features/invoicing/fourthImg.png';
import SecondImg from 'assets/features/invoicing/secondImg.png';
import SixthImg from 'assets/features/invoicing/sixthImg.png';
import ThirdImg from 'assets/features/invoicing/thirdImg.png';
import TriangleDownLeft from 'assets/features/triangles/triangleDownLeft.png';
import TriangleDownRight from 'assets/features/triangles/triangleDownRight.png';
import TriangleUpLeft from 'assets/features/triangles/triangleUpLeft.png';
import TriagleUpRight from 'assets/features/triangles/triangleUpRight.png';

export const headerInfoForFeaturesPages = {
  info: 'Tools to manage and record financial transactions include supplier settlements, invoicing, escrow accounts, referral programs, loyalty, and discount programs.',
  title: 'Invoicing and Customizable Operations',
  details: 'Mold your business logic to fit your needs',
};

export const contentInfoForFeaturesPages = [
  {
    title: 'Invoicing',
    description: [
      'Generate and manage invoices seamlessly within the system. This functionality is integral for businesses that require consistent and accurate billing and payment tracking.',
    ],
    image: FirstImg,
    imageWidth: { xs: '310px', sm: '584px', md: '590px' },
    imageHeight: { xs: '227px', sm: '427px', md: '438px' },
    gradient: (
      <>
        <FirstGradientBox />
        <SecondGradientBox />
      </>
    ),
    triangle: TriagleUpRight,
    comingSoon: true,
  },
  {
    title: 'Escrow Accounts',
    description: [
      'Secure funds in escrow accounts that release payments only when predefined conditions are met. Ideal for transactions requiring verification or fulfillment of specific terms.',
    ],
    image: SecondImg,
    imageWidth: { xs: '310px', sm: '584px', md: '642px' },
    imageHeight: { xs: '231px', sm: '436px', md: '465px' },
    triangle: TriangleDownLeft,
  },
  {
    title: 'Customizable Payment Flows',
    description: [
      'Tailor payment processes to fit unique business models. This feature is essential for managing diverse payment scenarios that may vary by customer or transaction type.',
    ],
    image: ThirdImg,
    imageWidth: { xs: '310px', sm: '584px', md: '578px' },
    imageHeight: { xs: '200px', sm: '375px', md: '375px' },
    gradient: <ThirdGradientBox />,
    triangle: TriangleDownRight,
  },
  {
    title: 'Referral Programs',
    description: [
      'Implement and manage referral programs that incentivize existing customers to attract new ones, enhancing business growth and customer acquisition.',
    ],
    image: FourthImg,
    imageWidth: { xs: '310px', sm: '584px', md: '580px' },
    imageHeight: { xs: '225px', sm: '424px', md: '419px' },
    gradient: (
      <>
        <FourthGradientBox />
        <FifthGradientBox />
      </>
    ),
    triangle: TriangleUpLeft,
    comingSoon: true,
  },
  {
    title: 'Loyalty and Discount Programs',
    description: [
      'Develop and operate loyalty schemes and discount programs to retain customers and increase sales. These programs can be customized to align with marketing strategies and consumer behavior trends.',
    ],
    image: FifthImg,
    imageWidth: { xs: '310px', sm: '584px', md: '586px' },
    imageHeight: { xs: '228px', sm: '432px', md: '431px' },
    triangle: TriagleUpRight,
  },
  {
    title: 'Merchant and Vendor Payments',
    description: [
      'Automate and calculate payments to merchants and vendors with precision. This tool is crucial for businesses that rely on a network of suppliers and partners.',
    ],
    image: SixthImg,
    imageWidth: { xs: '310px', sm: '584px', md: '581px' },
    imageHeight: { xs: '218px', sm: '413px', md: '412px' },
    gradient: <SixthGradientBox />,
    triangle: TriangleDownLeft,
  },
];
