import FeaturesHeaderImg from 'assets/features/header/FeaturesHeaderImg.png';
import FeaturesHeaderMobileImg from 'assets/features/header/FeaturesHeaderMobileImg.jpg';
import FeaturesHeaderTabletImg from 'assets/features/header/FeaturesHeaderTabletImg.jpg';
import PortfolioHeaderImg from 'assets/portfolio/header/portfolioHeader.png';
import PortfolioHeaderMobileImg from 'assets/portfolio/header/portfolioHeaderMobile.jpg';
import PortfolioHeaderTabletImg from 'assets/portfolio/header/portfolioHeaderTablet.jpg';
import ServeHeaderImg from 'assets/serve/serveHeader.png';
import ServeHeaderMobileImg from 'assets/serve/serveHeaderMobile.jpg';
import ServeHeaderTabletImg from 'assets/serve/serveHeaderTablet.jpg';

export const gradientHeaderBoxContent = {
  features: {
    title: 'Build your financial app',
    details: 'Select the features you need, and watch us bring it to life!',
    src: {
      desktop: FeaturesHeaderImg,
      tablet: FeaturesHeaderTabletImg,
      mobile: FeaturesHeaderMobileImg,
    },
  },
  serve: {
    title: 'Who we serve?',
    details: 'Tailored financial solutions for startups, enterprises, and developers',
    src: {
      desktop: ServeHeaderImg,
      tablet: ServeHeaderTabletImg,
      mobile: ServeHeaderMobileImg,
    },
  },
  portfolio: {
    title: 'We are trusted',
    details:
      'Case Studies: Leading companies choose us for reliable, efficient, and innovative solutions',
    src: {
      desktop: PortfolioHeaderImg,
      tablet: PortfolioHeaderTabletImg,
      mobile: PortfolioHeaderMobileImg,
    },
  },
};
