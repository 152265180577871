import {
  FirstGradientBox,
  SecondGradientBox,
  ThirdGradientBox,
} from 'components/gradient-boxes/ForFeaturePage';

import FirstImg from 'assets/features/crypto/firstImg.png';
import SecondImg from 'assets/features/crypto/secondImg.png';
import ThirdImg from 'assets/features/crypto/thirdImg.png';
import TriangleDownLeft from 'assets/features/triangles/triangleDownLeft.png';
import TriangleDownRight from 'assets/features/triangles/triangleDownRight.png';
import TriagleUpRight from 'assets/features/triangles/triangleUpRight.png';

export const headerInfoForFeaturesPages = {
  info: 'Pre-build integration with popular blockchain solutions like BTC, ETH, LTC, TRX, and others, for crypto transaction processing',
  title: 'Crypto Operations and Asset Management',
  details: 'Comprehensive Crypto Management Tools',
};

export const contentInfoForFeaturesPages = [
  {
    title: 'Extensive Crypto Network Integration',
    description: [
      'Support connections with the most important crypto networks, offering a broad range of crypto transaction capabilities.',
    ],
    image: FirstImg,
    imageWidth: { xs: '310px', sm: '584px', md: '598px' },
    imageHeight: { xs: '226px', sm: '425px', md: '434px' },
    gradient: (
      <>
        <FirstGradientBox />
        <SecondGradientBox />
      </>
    ),
    triangle: TriagleUpRight,
  },
  {
    title: 'NFT & asset support',
    description: [
      'Future updates include support for major NFT standards (ERC 721 and ERC1155), catering to diverse business models such as ticketing and merchant services.',
    ],
    comingSoon: true,
    image: SecondImg,
    imageWidth: { xs: '310px', sm: '584px', md: '597px' },
    imageHeight: { xs: '204px', sm: '383px', md: '391px' },
    triangle: TriangleDownLeft,
  },
  {
    title: 'Customizable Crypto Operations',
    description: [
      'Customize crypto operations, including setting parameters like network type and node IP addresses for enhanced control.',
    ],
    image: ThirdImg,
    imageWidth: { xs: '310px', sm: '584px', md: '586px' },
    imageHeight: { xs: '211px', sm: '398px', md: '400px' },
    gradient: <ThirdGradientBox />,
    triangle: TriangleDownRight,
    comingSoon: true,
  },
];
