import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useMediaSize } from 'hooks/useDeviceDetector';
import React from 'react';

import { Box } from '@mui/material';

import { ReactComponent as ArrCoin } from 'assets/home/first/arrCoin.svg';
import { ReactComponent as BitCoin } from 'assets/home/first/bitCoin.svg';
import { ReactComponent as Circle2 } from 'assets/home/first/circle2.svg';
import { ReactComponent as Circle } from 'assets/home/first/circle.svg';
import { ReactComponent as EurCoin } from 'assets/home/first/eurCoinSvg.svg';
import fifthScreenshoot from 'assets/home/first/fifthScreenshoot.png';
import firstScreenshoot from 'assets/home/first/firstScreenshoot.png';
import fourthScreenshoot from 'assets/home/first/fourthScreenshoot.png';
import { ReactComponent as FuntCoin } from 'assets/home/first/funtCoin.svg';
import secondScreenshoot from 'assets/home/first/secondScreenshoot.png';
import thirdScreenshoot from 'assets/home/first/thirdScreenshoot.png';
import { ReactComponent as UsdCoin } from 'assets/home/first/usdCoin.svg';

gsap.registerPlugin(MotionPathPlugin);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(useGSAP);

export const GroupedPhoto = () => {
  const { isPhone, isTablet,isDesktop } = useMediaSize();
  useGSAP(() => {
    const tl = gsap.timeline();
    tl.from('.thirdScreenshoot', {
      duration: 0.6,
      opacity: 0.5,
      scale: 0,
    })
      .from('.firstScreenshoot', {
        duration: 0.6,
        opacity: 0.5,
        scale: 0,
        xPercent: 100,
        yPercent: 100,
      })
      .from('.fourthScreenshoot', {
        duration: 0.6,
        opacity: 0.5,
        scale: 0,
        xPercent: 100,
        yPercent: -100,
      })
      .from('.secondScreenshoot', {
        duration: 0.6,
        opacity: 0.5,
        scale: 0,
        xPercent: -100,
        yPercent: 100,
      })

      .from('.fifthScreenshoot', {
        duration: 0.6,
        opacity: 0.5,
        scale: 0,
        xPercent: -100,
        yPercent: -100,
      });
  });
  useGSAP(
    () => {
      const circ = document.getElementById('circle1') as SVGCircleElement | null;
      const circ2 = document.getElementById('circle2') as SVGCircleElement | null;
      if (circ && circ2) {
        const pathConv = MotionPathPlugin.convertToPath(circ);
        const pathConv2 = MotionPathPlugin.convertToPath(circ2);
        gsap.to('.EurCoin', {
          duration: 3,
          ease: 'none',
          motionPath: {
            path: pathConv[0],
            align: pathConv[0],
            alignOrigin: [0.5, 0.5],
            autoRotate: false,
            end: 0.56,
          },
        });

        gsap.to('.FuntCoin', {
          duration: 3,
          ease: 'none',
          motionPath: {
            path: pathConv2[0],
            align: pathConv2[0],
            alignOrigin: [0.5, 0.5],
            autoRotate: false,
            end: 0.45,
          },
        });
        gsap.to('.UsdCoin', {
          duration: 3,
          ease: 'none',
          motionPath: {
            path: pathConv2[0],
            align: pathConv2[0],
            alignOrigin: [0.5, 0.5],
            autoRotate: false,
            start: 0.4,
            end: 0.85,
          },
        });
        gsap.to('.BitCoin', {
          duration: 3,
          ease: 'none',
          motionPath: {
            path: pathConv[0],
            align: pathConv[0],
            alignOrigin: [0.5, 0.5],
            autoRotate: false,
            start: 0.3,
            end: 0.78,
          },
        });
        gsap.to('.ArrCoin', {
          duration: 3,
          ease: 'none',
          motionPath: {
            path: pathConv[0],
            align: pathConv[0],
            alignOrigin: [0.5, 0.5],
            autoRotate: false,
            end: 0.2,
          },
        });
      }
    },
    { dependencies: [isPhone, isTablet,isDesktop] }
  );
  return (
    <Box
      className="home_first_img"
      position="relative"
      sx={{
        mr: {
          xs: 7,
          sm: 8,
          md: 10,
        },
        width: { xs: '200px', sm: '267px', md: '518px' },
        height: { xs: '247px', sm: '274px', md: '388px' },
        mt: { xs: '36px', sm: '10px', md: 32 },
      }}
    >
      <Box
        className="firstScreenshoot"
        component="img"
        src={firstScreenshoot}
        width="32%"
        position="absolute"
        top={{ xs: '-35%', sm: '-45%', md: '-45%' }}
        left="-2%"
        zIndex={2}
      />
      <Box
        className="secondScreenshoot"
        component="img"
        src={secondScreenshoot}
        width="60%"
        position="absolute"
        top={{ xs: '-10%', sm: '-20%', md: '-10%' }}
        left="95%"
        zIndex={2}
      />
      <Box
        className="thirdScreenshoot"
        component="img"
        src={thirdScreenshoot}
        width="130%"
        height="auto"
        position="absolute"
        top="0"
      />
      <Box
        className="fourthScreenshoot"
        component="img"
        src={fourthScreenshoot}
        width="39%"
        position="absolute"
        left="-10%"
        top={{ xs: '45%', sm: '55%', md: '65%' }}
      />
      <Box
        className="fifthScreenshoot"
        component="img"
        src={fifthScreenshoot}
        width="60%"
        position="absolute"
        left="80%"
        top={{ xs: '45%', sm: '55%', md: '65%' }}
      />
      <Circle
        style={{
          position: 'absolute',
          top: '-40%',
          left: '-25%',
          zIndex: -2,
          width: '130%',
          height: 'auto',
        }}
      />
      <Circle2
        style={{
          position: 'absolute',
          top: '-45%',
          left: '-10%',
          zIndex: -2,
          width: '130%',
          height: 'auto',
        }}
      />
      <EurCoin className="EurCoin" style={{ position: 'absolute', zIndex: -2, width: '25%' }} />
      <FuntCoin className="FuntCoin" style={{ position: 'absolute', zIndex: -2, width: '20%' }} />
      <UsdCoin className="UsdCoin" style={{ position: 'absolute', zIndex: -2, width: '30%' }} />
      <BitCoin className="BitCoin" style={{ position: 'absolute', zIndex: -2, width: '25%' }} />
      <ArrCoin className="ArrCoin" style={{ position: 'absolute', zIndex: -2, width: '20%' }} />
    </Box>
  );
};
