import {
  FifthGradientBox,
  FirstGradientBox,
  FourthGradientBox,
  SecondGradientBox,
  SixthGradientBox,
  ThirdGradientBox,
} from 'components/gradient-boxes/ForFeaturePage';

import FifthImg from 'assets/features/multicurrency/fifthImg.png';
import FirstImg from 'assets/features/multicurrency/firstImg.png';
import FourthImg from 'assets/features/multicurrency/fourthImg.png';
import SecondImg from 'assets/features/multicurrency/secondImg.png';
import SixthImg from 'assets/features/multicurrency/sixthImg.png';
import ThirdImg from 'assets/features/multicurrency/thirdImg.png';
import TriangleDownLeft from 'assets/features/triangles/triangleDownLeft.png';
import TriangleDownRight from 'assets/features/triangles/triangleDownRight.png';
import TriangleUpLeft from 'assets/features/triangles/triangleUpLeft.png';
import TriagleUpRight from 'assets/features/triangles/triangleUpRight.png';

export const headerInfoForFeaturesPages = {
  info: 'A wallet with ledger integration that provides transaction control and management of multiple wallets that utilize different currencies or assets.',
  title: 'Multicurrency wallets',
  details: 'Create any wallets you need as easy as building Lego: simple, fast, and functional',
};

export const contentInfoForFeaturesPages = [
  {
    title: 'Multicurrency account creation',
    description: [
      'From Fiat and cryptocurrencies to loyalty points and custom value units, our platform aims to simplify the management of diverse assets.',
      'We invite you to utilize our powerful transactional engine to seamlessly integrate any type of currency or asset into your digital wallet, with no scope or scale limitations. Experience flexible and limitless asset management.',
    ],
    image: FirstImg,
    imageWidth: { xs: '310px', sm: '584px', md: '565px' },
    imageHeight: { xs: '204px', sm: '384px', md: '385px' },
    gradient: (
      <>
        <FirstGradientBox />
        <SecondGradientBox />
      </>
    ),
    triangle: TriagleUpRight,
  },
  {
    title: 'Top up funds',
    description: [
      'Fund your wallet in your chosen currency through an external payment service. Process payments via Stripe, PayPal, Wise, etc and BTC, ETH, etc cryptocurrencies, or integrate your payment system.',
    ],
    image: SecondImg,
    imageWidth: { xs: '310px', sm: '584px', md: '592px' },
    imageHeight: { xs: '241px', sm: '453px', md: '453px' },
    triangle: TriangleDownLeft,
  },
  {
    title: 'Transfer funds',
    description: [
      'With our platform, moving funds between your wallets is simple and efficient. Set up your rules, flows, commissions, and combine transaction in one operation.',
      'Enjoy the freedom of multi-currency transfers, allowing you to send and receive money in various currencies without hassle.',
    ],
    image: ThirdImg,
    imageWidth: { xs: '310px', sm: '584px', md: '570px' },
    imageHeight: { xs: '216px', sm: '408px', md: '407px' },
    gradient: <ThirdGradientBox />,
    triangle: TriangleDownRight,
  },
  {
    title: 'Exchange Funds',
    description: [
      'Conduct efficient fund exchanges between different currencies, supporting both fiat and cryptocurrencies. Convert your assets smoothly within your wallet to meet your immediate needs without the hassle of external exchanges.',
    ],
    image: FourthImg,
    imageWidth: { xs: '310px', sm: '584px', md: '580px' },
    imageHeight: { xs: '211px', sm: '397px', md: '396px' },
    comingSoon: true,
    gradient: (
      <>
        <FourthGradientBox />
        <FifthGradientBox />
      </>
    ),
    triangle: TriangleUpLeft,
  },
  {
    title: 'Withdraw funds',
    description: [
      'Easily withdraw funds from their wallets using external payment systems such as Stripe, PayPal, Wise, etc, and cryptocurrencies like BTC, ETH, etc.',
      'For added security, each withdrawal request must be verified by a configuration administrator via the Admin Panel.',
      'Additionally, configure auto-withdrawal settings for requests that meet specific criteria, such as request amount or currency, to automate the process and enhance operational efficiency.',
    ],
    image: FifthImg,
    imageWidth: { xs: '310px', sm: '584px', md: '579px' },
    imageHeight: { xs: '220px', sm: '386px', md: '385px' },
    triangle: TriagleUpRight,
  },
  {
    title: 'Schedule your Payments and Transfers',
    description: [
      'Plan and execute payments and transfers on a predetermined schedule. Ideal for regular payments such as salaries, subscriptions, or recurring service fees, our system allows you to set up and forget scheduled transfers, ensuring funds are always where they need to be when required.',
    ],
    image: SixthImg,
    imageWidth: { xs: '310px', sm: '584px', md: '570px' },
    imageHeight: { xs: '204px', sm: '384px', md: '384px' },
    gradient: <SixthGradientBox />,
    triangle: TriangleDownLeft,
    comingSoon: true,
  },
];
