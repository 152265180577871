import { useMediaSize } from 'hooks/useDeviceDetector';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import Button from 'components/common/Button';
import { ServeCard } from 'components/home/serve/ServeCard/ServeCard';

import OfficeImg from 'assets/home/serve/office.png';
import RocketImg from 'assets/home/serve/rocket.png';

interface ICardContent {
  image: string;
  title: string;
  description: string;
  hash: string;
}

const CARD_CONTENT: ICardContent[] = [
  {
    image: RocketImg,
    title: 'Startups',
    description:
      'Our solutions are tailored to the unique needs of startups, giving them the out-of-the-box tools and support they need to thrive (quick launch) in a competitive marketplace',
    hash: 'Startups',
  },
  {
    image: OfficeImg,
    title: 'Enterprises',
    description:
      'We provide comprehensive solutions for businesses to optimize financial operations and drive growth',
    hash: 'Enterprises',
  },
];

export const HomeServe = () => {
  const navigate = useNavigate();
  const { isMobileDevice } = useMediaSize();
  return (
    <Box
      className="home_serve_section"
      sx={{
        background: 'white',
        padding: {
          xs: '72px 16px 72px 16px',
          sm: '72px 20px 72px 20px',
          md: '100px 24px 100px 24px',
        },
      }}
          >
      <Box
        sx={{
          maxWidth: '1308px',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          flexWrap: 'nowrap',
          margin: 'auto',
          boxSizing: 'border-box',
          gap: { xs: '24px', sm: '' },
        }}
      >
        <Box
          className={'home_serve_title'}
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            zIndex:3
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '30px', sm: '32px', md: '52px' },
              fontWeight: { xs: 700, md: 800 },
              lineHeight: { xs: '36px', sm: '41.6px', md: '62.4px' },
              textAlign: 'left',
            }}
          >
            Serving Innovators:
            <br /> Startups & Businesses
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '16px', sm: '18px' },
              fontWeight: { xs: 400 },
              lineHeight: { xs: '24px', sm: '27px' },
              textAlign: 'left',
              marginTop: { xs: '8px', sm: '16px' },
            }}
          >
            Our services are designed to support businesses at all stages of development, from
            budding start-ups to established enterprises
          </Typography>
          {!isMobileDevice && (
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: '212px',
                height: '48px',
                borderRadius: '15px',
                border: '1px inherit solid',
                mt: '24px',
                zIndex:3
              }}
              onClick={() => {
                navigate('/serve');
              }}
            >
              Learn more
            </Button>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            flex: 1,
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            gap: '24px',
          }}
        >
          {CARD_CONTENT.map((card) => (
            <ServeCard
              key={card.title}
              image={card.image}
              title={card.title}
              description={card.description}
              hash={card.hash}
            />
          ))}
        </Box>
        {isMobileDevice && (
          <Button
            variant="contained"
            color="primary"
            sx={{
              zIndex:3,
              width: { xs: '100%', sm: '212px' },
              height: '48px',
              borderRadius: '15px',
              border: '1px inherit solid',
              mt: '24px',
              alignSelf: { xs: 'center', sm: 'flex-start' },
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '24px',
            }}
            onClick={() => {
              navigate('/serve');
            }}
          >
            Learn more
          </Button>
        )}
      </Box>
    </Box>
  );
};
