import EnterpriseAdvantageThird from 'assets/serve/animations/enterprise/ComprehensiveWhiteLabel.json';
import EnterpriseAdvantageFourth from 'assets/serve/animations/enterprise/DedicatedDevelopmentTeam.json';
import EnterpriseAdvantageFifth from 'assets/serve/animations/enterprise/PostPurchaseSupport.json';
import EnterpriseAdvantageFirst from 'assets/serve/animations/enterprise/SwiftDeployment.json';
import EnterpriseAdvantageSecond from 'assets/serve/animations/enterprise/VendorIndependent.json';
import StartupAdvantageFirst from 'assets/serve/animations/sartUp/AffordableStartIcon.json';
import StartupAdvantageFifth from 'assets/serve/animations/sartUp/FlexibleCancellationPolicy.json';
import StartupAdvantageFourth from 'assets/serve/animations/sartUp/ProductDevelopmentShortcut.json';
import StartupAdvantageSecond from 'assets/serve/animations/sartUp/RegulatoryComplianceAutomation.json';
import StartupAdvantageThird from 'assets/serve/animations/sartUp/StreamlinedIntegrations.json';

interface AdvantagesInfo {
  img: any;
  text: string;
  description?: string;
}

export const advantagesStartupsInfo: AdvantagesInfo[] = [
  {
    img: StartupAdvantageFirst,
    text: 'Affordable start',
  },
  {
    img: StartupAdvantageSecond,
    text: 'Regulatory compliance automation',
  },
  {
    img: StartupAdvantageThird,
    text: 'Streamlined integrations',
  },
  {
    img: StartupAdvantageFourth,
    text: 'Product development shortcut',
  },
  {
    img: StartupAdvantageFifth,
    text: 'Flexible cancellation policy',
  },
];

export const advantagesEnterprisesInfo: AdvantagesInfo[] = [
  {
    img: EnterpriseAdvantageFirst,
    text: 'Swift Deployment',
  },
  {
    img: EnterpriseAdvantageSecond,
    text: 'Vendor-independent',
    description: '(we will pass the code and knowledge base to your team)',
  },
  {
    img: EnterpriseAdvantageThird,
    text: 'Comprehensive White Label',
  },
  {
    img: EnterpriseAdvantageFourth,
    text: 'Dedicated development team',
  },
  {
    img: EnterpriseAdvantageFifth,
    text: 'Post-purchase support',
  },
];
