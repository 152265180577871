import { useMediaSize } from 'hooks/useDeviceDetector';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { theme } from 'theme';

import styles from './Footer.module.css';

import { Box, Typography, Link as MuiLink } from '@mui/material';

import { pages } from 'components/common/AppBar';
import { TABS } from 'components/features/TabsNavigation';

import FooterBackground from 'assets/footer/footerBackground.png';
import { ReactComponent as In } from 'assets/footer/in.svg';
import { ReactComponent as Logo } from 'assets/footer/logoWhite.svg';
import Mobile_FooterBackground from 'assets/footer/mobile_footerBg.png';

export const TermsPoliciesCookies = ({ color }: { color?: string }) => {
  const { isPhone, isMobileDevice } = useMediaSize();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row', md: 'column' },
        justifyContent: 'space-between',
        alignItems: 'start',
        flexWrap: 'wrap',
        gap: '24px',
      }}
    >
      <Link
        to="/privacy-policy"
        style={{
          color: color,
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '21px',
        }}
      >
        Privacy Policy
      </Link>
      <Link
        to="/terms-conditions"
        style={{
          color: color,
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '21px',
        }}
      >
        Terms of Service
      </Link>
      <Link
        to="/cookies-settings"
        style={{
          color: color,
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '21px',
        }}
      >
        Cookies Settings
      </Link>
      <MuiLink
        href="https://www.linkedin.com/showcase/clever-wallet"
        underline="none"
        sx={{ alignSelf: { xs: 'start', sm: 'end' }, display: 'flex', gap: '10px' }}
        target="_blank"
      >
        {!isMobileDevice && (
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '21px',
              color: 'white',
            }}
          >
            Linkedin
          </Typography>
        )}
        <In width={isPhone ? 50 : 18} height={isPhone ? 50 : 18} />
      </MuiLink>
    </Box>
  );
};

export const Footer = () => {
  const navigate = useNavigate()
  const { isPhone, isMobileDevice } = useMediaSize();
  return (
    <Box
      sx={{
        minHeight: { xs: '961px', sm: '463px', md: '512px' },
        padding: {
          xs: '72px 16px 36px 16px',
          sm: '72px 20px 36px 20px',
          md: '100px 24px 50px 24px',
        },
        boxSizing: 'border-box',
        background: '#1D3061',
        position: 'relative',
        zIndex: 0,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
          opacity: 0.3,
        }}
        component={'img'}
        src={isPhone ? Mobile_FooterBackground : FooterBackground}
      />
      <Box
        sx={{
          height: '180px',
          maxWidth: '1313px',
          display: 'flex',
          flexDirection: 'column',
          margin: 'auto',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between' }}>
          <Box
            sx={{
              maxWidth: '785px',
              minHeight: '214px',
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: 'space-between',
              gap: { xs: '18px', sm: '100px' },
            }}
          >
            <Logo
              style={{cursor:'pointer'}}
              onClick={() => {
              navigate('');
            }} />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '18px',
                justifyContent: 'space-between',
                alignItems: 'start',
                flexWrap: 'wrap',
              }}
            >
              {pages.map((item) => {
                if (item.text !== 'Features' && !isPhone) {
                  return (
                    <Link className={styles.link} key={item.link} to={item.link}>
                      {item.text}
                    </Link>
                  );
                } else if (isPhone) {
                  return (
                    <Box key={item.link}>
                      <Link className={styles.link} to={item.link}>
                        {item.text}
                      </Link>
                      {item.text === 'Features' &&
                        TABS.map((tab) => (
                          <Box
                            key={tab.path}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '8px',
                              alignItems: 'start',
                              flexWrap: 'wrap',
                            }}
                          >
                            <Link
                              className={styles.link}
                              style={{
                                fontWeight: 500,
                                fontSize: '14px',
                                lineHeight: '21px',
                                padding: '4px 0px',
                              }}
                              key={tab.path}
                              to={`features/${tab.path}`}
                            >
                              {tab.title}
                            </Link>
                          </Box>
                        ))}
                    </Box>
                  );
                }
              })}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'start',
                flexWrap: 'wrap',
              }}
            >
              {!isPhone && (
                <>
                  <Link className={styles.link} key="features" to="features">
                    Features
                  </Link>
                  {TABS.map((tab) => (
                    <Link
                      className={styles.link}
                      style={{
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '21px',
                      }}
                      key={tab.path}
                      to={`features/${tab.path}`}
                    >
                      {tab.title}
                    </Link>
                  ))}
                </>
              )}
            </Box>
          </Box>
          {!isMobileDevice && <TermsPoliciesCookies color={theme.palette.common.white} />}
        </Box>
        <Box
          sx={{
            width: '100%',
            minHeight: '54px',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row', md: 'column' },
            justifyContent: { xs: 'start', sm: 'space-between', md: 'end' },
            alignItems: { xs: 'start', sm: 'end' },
            borderTop: '1px solid #FFFFFF',
            mt: '48px',
            gap: '24px',
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '21px',
              textAlign: 'left',
              color: 'rgba(255, 255, 255, 1)',
              mt: { xs: '48px', sm: '' },
            }}
          >
            © 2024 Clever Wallet. All rights reserved
          </Typography>
          {isMobileDevice && <TermsPoliciesCookies color={theme.palette.common.white} />}
        </Box>
      </Box>
    </Box>
  );
};
