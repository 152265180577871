export interface PrivacyPolicyParagraph {
  title: string;
  description: string[];
}

export interface ILink {
  phrase: string;
  url: string;
}

export const PRIVACY_POLICY_PARAGRAPHS: PrivacyPolicyParagraph[] = [
  {
    title: '1. Information We Collect',
    description: [
      '1.1 Personal Information: We may collect personal information such as your name, email address, phone number, and payment information when you create an account or use our services.',
      '1.2 Usage Data: We collect information on how you use the Platform, such as the pages you visit, the features you use, and the actions you take.',
      '1.3 Device Information: We collect information about the device you use to access the Platform, including the device type, operating system, and browser type.',
      '1.4 Location Data: We may collect and process information about your actual location using various technologies such as IP address, GPS, and other sensors.',
    ],
  },
  {
    title: '2. How We Use Your Information',
    description: [
      '2.1 Service Provision: We use your information to provide, maintain, and improve our services, including personalizing your experience on the Platform.',
      '2.2 Communication: We use your information to communicate with you, including sending service-related messages, updates, and promotional offers.',
      '2.3 Security: We use your information to protect the security and integrity of our Platform and to prevent and detect fraud and abuse.',
      '2.4 Compliance: We use your information to comply with legal obligations, such as performing KYC/AML/CFT checks.',
    ],
  },
  {
    title: '3. Sharing Your Information',
    description: [
      '3.1 Third-Party Service Providers: We may share your information with third-party service providers who perform services on our behalf, such as payment processing and data analysis.',
      '3.2 Legal Requirements: We may disclose your information if required by law or in response to legal processes, such as a court order or subpoena.',
      '3.3 Business Transfers: We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.',
    ],
  },
  {
    title: '4. Data Security',
    description: [
      'We implement security measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.',
    ],
  },
  {
    title: '5. Your Rights',
    description: [
      '5.1 Access and Correction: You have the right to access and correct your personal information held by us. You can update your account information through the Platform or contact us directly.',
      '5.2 Data Deletion: You have the right to request the deletion of your personal information. Please note that we may retain certain information as required by law or for legitimate business purposes.',
      '5.3 Opt-Out: You have the right to opt-out of receiving promotional communications from us by following the unsubscribe instructions in those communications or contacting us directly.',
    ],
  },
  {
    title: '6. Cookies and Tracking Technologies',
    description: [
      `We use cookies and similar tracking technologies to collect and use personal information about you, including to serve interest-based advertising. For more information about the types of cookies we use and how to manage them, please refer to our Cookie Policy.`,
    ],
  },
  {
    title: '7. International Data Transfers',
    description: [
      'Your information may be transferred to, and maintained on, computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those in your jurisdiction. By using our Platform, you consent to such transfers.',
    ],
  },
  {
    title: '8. Changes to This Privacy Policy',
    description: [
      'We may update this Privacy Policy from time to time. Any changes will be posted on this page, and you are advised to review this Privacy Policy periodically. Your continued use of the Platform after any changes signifies your acceptance of the new Privacy Policy.',
    ],
  },
  {
    title: '9. Contact Us',
    description: [
      'If you have any questions or concerns about this Privacy Policy, please contact us at support@cleverwallet.app.',
    ],
  },
  {
    title: '10. Governing Law',
    description: [
      'This Privacy Policy shall be governed by and construed in accordance with the laws of Tennessee, without regard to its conflict of law provisions.' +
        ' By using the Clever Wallet Platform, you agree to the terms of this Privacy Policy. If you do not agree with these terms, please do not use the Platform.',
    ],
  },
];

export const PRIVACY_POLICY_PARAGRAPHS_LINKS: ILink[] = [
  { phrase: 'Cookie Policy', url: '/cookies-settings' },
];
