import { backgroundGradient } from 'consts/style';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SwiperCore from 'swiper';
import { EffectFade, Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Box, Typography } from '@mui/material';

import MobileBlock from 'components/home/mobile/MobileBlock/MobileBlock';

import Add_module_1 from 'assets/home/mobile/add_module/Add_module_1.png';
import Add_module_2 from 'assets/home/mobile/add_module/Add_module_2.png';
import Add_module_3 from 'assets/home/mobile/add_module/Add_module_3.png';
import ConnectorsImg from 'assets/home/mobile/connectors.png';
import Crypto_operation_1 from 'assets/home/mobile/crypto_operation/Crypto_operation_1.png';
import Crypto_operation_2 from 'assets/home/mobile/crypto_operation/Crypto_operation_2.png';
import Invoicing_operations_1 from 'assets/home/mobile/invoicing_operations/Invoicing_operations_1.png';
import Invoicing_operations_2 from 'assets/home/mobile/invoicing_operations/Invoicing_operations_2.png';
import Wrapper from 'assets/home/mobile/mobileWrapper.png';
import Mobile_wallet_1 from 'assets/home/mobile/mobile_wallet/Mobile_wallet_1.png';
import Mobile_wallet_2 from 'assets/home/mobile/mobile_wallet/Mobile_wallet_2.png';
import Mobile_wallet_3 from 'assets/home/mobile/mobile_wallet/Mobile_wallet_3.png';
import Multicurrency_Wallets_1 from 'assets/home/mobile/multicurrency_Wallets/Multicurrency_Wallets_1.png';
import Multicurrency_Wallets_2 from 'assets/home/mobile/multicurrency_Wallets/Multicurrency_Wallets_2.png';
import Payment_system_integrations_1 from 'assets/home/mobile/payment_system_integrations/Payment_system_integrations_1.png';
import Payment_system_integrations_2 from 'assets/home/mobile/payment_system_integrations/Payment_system_integrations_2.png';

import { useMediaSize } from '../../../hooks/useDeviceDetector';
import { scrollHandler } from '../../../utils/scrollHandler';

interface IBlockContent {
  title: string;
  description: string;
  images: string[];
  tab?: string;
}

const BLOCK_CONTENT: IBlockContent[] = [
  {
    title: 'Multicurrency Wallets',
    description:
      'A multi-currency wallet with ledger integration that provides transaction control and management of multiple wallets in different currencies, including fiat and cryptocurrencies. Guarantees ease of use and instant access to financial data',
    images: [
      Multicurrency_Wallets_1,
      Multicurrency_Wallets_2,
      // Multicurrency_Wallets_1,
      // Multicurrency_Wallets_2,
      // Multicurrency_Wallets_1,
      // Multicurrency_Wallets_2,
    ],
    tab: '/features/multicurrency',
  },
  {
    title: 'Mobile wallet',
    description:
      'White label mobile app ready for publication in iOS and Android stores, or for integrating all functionality into existing mobile apps. It reflects all the core features of the web version',
    images: [
      Mobile_wallet_1,
      Mobile_wallet_2,
      Mobile_wallet_3,
      // Mobile_wallet_1,
      // Mobile_wallet_2,
      // Mobile_wallet_3,
    ],
    tab: '/features/mobile-wallet',
  },
  {
    title: 'Invoicing & customizable operations',
    description:
      'Tools to manage and record financial transactions include supplier settlements, invoicing, escrow accounts, referral programs, loyalty and discount programs',
    images: [
      Invoicing_operations_1,
      Invoicing_operations_2,
      // Invoicing_operations_1,
      // Invoicing_operations_2,
      // Invoicing_operations_1,
      // Invoicing_operations_2,
    ],
    tab: '/features/invoicing',
  },
  {
    title: 'Payment system integrations',
    description:
      'Pre-built integrations with Stripe, PayPal, and others, providing flexibility and the ability to process transactions in different currencies, customized to customer requirements',
    images: [
      Payment_system_integrations_1,
      Payment_system_integrations_2,
      // Payment_system_integrations_1,
      // Payment_system_integrations_2,
      // Payment_system_integrations_1,
      // Payment_system_integrations_2,
    ],
    tab: '/features/payment',
  },
  {
    title: 'Crypto operation and asset management ',
    description:
      'Pre-build integration with popular blockchain solutions like BTC, ETH, LTC, TRX, and others, for crypto transaction processing',
    images: [
      Crypto_operation_1,
      Crypto_operation_2,
      // Crypto_operation_1,
      // Crypto_operation_2,
      // Crypto_operation_1,
      // Crypto_operation_2,
    ],
    tab: '/features/crypto',
  },
  {
    title: 'Add your custom module',
    description:
      'We will develop specialized modules tailored to your product. If the module benefits our system, we will not charge for it',
    images: [Add_module_1, Add_module_2, Add_module_3, Add_module_1, Add_module_2, Add_module_3],
  },
];

export const HomeMobile = () => {
  const { isPhone } = useMediaSize();
  const [currentImages, setCurrentImages] = useState<string[]>(BLOCK_CONTENT[0].images);
  const navigate = useNavigate();
  const swiperRef = useRef<SwiperCore | null>(null);
  const [mount, setMount] = useState(0);
  useEffect(() => {
    if (!isPhone) {
      setTimeout(() => {
        swiperRef.current?.update();
        swiperRef.current?.slideNext();
      }, 3000);
    } else {
      swiperRef.current?.update();
    }
  }, [currentImages[0], mount]);
  return (
    <Box
      className="home_mobile_section"
      sx={{
        padding: {
          xs: '72px 16px 72px 16px',
          sm: '72px 20px 72px 20px',
          md: '100px 24px 24px 24px',
        },
        maxWidth: '100%',
        minHeight: { xs: 0, md: '1285px' },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxSizing: 'border-box',
        background: backgroundGradient,
        position: 'relative',
        zIndex: 2,
      }}
      onMouseLeave={() => {
        setCurrentImages(BLOCK_CONTENT[0].images);
      }}
    >
      <Box sx={{ maxWidth: '935px', minHeight: '97px' }}>
        <Typography
          sx={{
            fontSize: { xs: '30px', sm: '32px', md: '52px' },
            fontWeight: { xs: 700, sm: 700, md: 800 },
            lineHeight: { xs: '36px', sm: '41px', md: '62.4px' },
            textAlign: 'center',
            color: 'rgba(255, 255, 255, 1)',
          }}
        >
          Design Your Custom Financial Tool
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: '16px', sm: '18px' },
            fontWeight: { xs: 400 },
            lineHeight: { xs: '24px', sm: '27px' },
            textAlign: 'center',
            marginTop: '8px',
            color: 'rgba(255, 255, 255, 1)',
          }}
        >
          Choose Your Features and We will Build Your Solution
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          maxWidth: '1307px',
          minHeight: { xs: 0, sm: '932px' },
          mt: { xs: '48px', sm: '56px' },
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            maxWidth: '370px',
            position: 'relative',
            height: '751px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: { sm: 'end', md: 'center' },
          }}
        >
          {!isPhone && (
            <Box
              sx={{
                position: 'absolute',
                maxWidth: { sm: '255px', md: '370px' },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img style={{ height: 'auto', width: '100%' }} alt="mobile" src={Wrapper} />
            </Box>
          )}
          <Swiper
            ref={swiperRef}
            slidesPerView={1}
            spaceBetween={50}
            effect={!isPhone && 'fade'}
            autoplay={{ delay: 3000 }}
            fadeEffect={{ crossFade: true }}
            loop={true}
            pagination={{
              clickable: true,
              el: `.swiper_pagination`,
            }}
            navigation={false}
            modules={[EffectFade, Autoplay, Pagination]}
            onSwiper={(swiper: any) => {
              setMount((prev) => prev + 1);
              swiperRef.current = swiper;
            }}
          >
            {!isPhone &&
              currentImages.map((image, ind) => (
                <SwiperSlide key={image + ind}>
                  <Box
                    width="100%"
                    sx={{
                      display: 'flex',
                      justifyContent: { sm: 'end', md: 'center' },
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        maxHeight: '725px',
                        maxWidth: '330px',
                        height: { sm: '513px', md: '748px' },
                        width: { sm: '233px', md: '92%' },
                        mr: { sm: '11px', md: '0' },
                      }}
                      component="img"
                      alt="mobile"
                      src={image}
                    />
                  </Box>
                </SwiperSlide>
              ))}

            {isPhone &&
              BLOCK_CONTENT.map((item, ind) => (
                <SwiperSlide key={item.title + ind}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      gap: '16px',
                    }}
                  >
                    <Box
                      width="234px"
                      height="489px"
                      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      <Box
                        onClick={(e) => {
                          item.tab ? navigate(`${item.tab}`) : scrollHandler(e, 'demo');
                        }}
                        sx={{
                          position: 'absolute',
                          width: '234px',
                          height: '489px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        <img style={{ height: 'auto', width: '100%' }} alt="mobile" src={Wrapper} />
                      </Box>
                      <Box
                        component="img"
                        sx={{
                          height: 'auto',
                          width: '214px',
                        }}
                        alt="mobile"
                        src={item.images[0]}
                      />
                    </Box>
                    <MobileBlock
                      key={item.title}
                      setCurrentImages={setCurrentImages}
                      title={item.title}
                      images={item.images}
                      description={item.description}
                      tab={item.tab}
                    />
                  </Box>
                </SwiperSlide>
              ))}
            {isPhone && (
              <div
                className={`swiper_pagination`}
                style={{ marginTop: '20px', marginLeft: '130px' }}
              />
            )}
          </Swiper>
        </Box>
        <Box
          component="img"
          width="12%"
          height="800px"
          sx={{
            display: { xs: 'none', sm: 'flex' },
          }}
          alt="connectors"
          src={ConnectorsImg}
        ></Box>
        <Box sx={{ display: { xs: 'none', sm: 'flex' }, flexDirection: 'column', gap: '16px' }}>
          {BLOCK_CONTENT.map((item) => (
            <MobileBlock
              key={item.title}
              setCurrentImages={setCurrentImages}
              title={item.title}
              images={item.images}
              description={item.description}
              tab={item.tab}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
