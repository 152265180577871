export interface CookieSettingsParagraphs {
  title: string;
  description: string[];
}

export interface ILink {
  phrase: string;
  url: string;
}

export const COOKIES_SETTINGS_PARAGRAPHS: CookieSettingsParagraphs[] = [
  {
    title: '1. What are Cookies?',
    description: [
      'Cookies are small text files that are placed on your device by websites you visit. They are widely used to make websites work, or work more efficiently, as well as to provide information to the owners of the site.',
    ],
  },
  {
    title: '2. Types of Cookies We Use',
    description: [
      'We use the following types of cookies on our Platform:',
      '2.1 Essential Cookies: These cookies are necessary for the operation of the Platform. They include, for example, cookies that enable you to log into secure areas of our Platform.',
      '2.2 Analytical/Performance Cookies: These cookies allow us to recognize and count the number of visitors and to see how visitors move around our Platform when they are using it. This helps us to improve the way our Platform works, for example, by ensuring that users are finding what they are looking for easily.',
      '2.3 Functional Cookies: These cookies are used to recognize you when you return to our Platform. This enables us to personalize our content for you, greet you by name, and remember your preferences (for example, your choice of language or region).',
      '2.4 Targeting Cookies: These cookies record your visit to our Platform, the pages you have visited, and the links you have followed. We will use this information to make our Platform and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.',
    ],
  },
  {
    title: '3. How We Use Cookies',
    description: [
      'We use cookies to:',
      '• Provide and enhance our services.',
      '• Understand and improve the performance and usability of the Platform.',
      '• Personalize your experience by remembering your preferences.',
      '• Deliver relevant advertising and track its effectiveness.',
      '• Maintain the security and integrity of our Platform.',
    ],
  },
  {
    title: '4. Third-Party Cookies',
    description: [
      'In addition to our own cookies, we may also use various third-party cookies to report usage statistics of the Platform and deliver advertisements. These third-party cookies are governed by the privacy policies of the respective third parties.',
    ],
  },
  {
    title: '5. Managing Cookies',
    description: [
      'You can manage or delete cookies using your browser settings. Here’s how to do it in some popular browsers:',
      '• Google Chrome: Chrome Cookie Settings',
      '• Mozilla Firefox: Firefox Cookie Settings',
      '• Safari: Safari Cookie Settings',
      '• Microsoft Edge: Edge Cookie Settings',
      'Please note that if you choose to block cookies, you may not be able to access all or parts of our Platform or some features may not function properly.',
    ],
  },
  {
    title: '6. Changes to This Cookie Policy',
    description: [
      'We may update this Cookie Policy from time to time. Any changes will be posted on this page, and you are advised to review this Cookie Policy periodically. Your continued use of the Platform after any changes signifies your acceptance of the new Cookie Policy.',
    ],
  },
  {
    title: '7. Contact Us',
    description: [
      'If you have any questions or concerns about this Cookie Policy, please contact us at support@cleverwallet.app.',
    ],
  },
];

export const COOKIES_SETTINGS_PARAGRAPHS_LINKS: ILink[] = [
  {
    phrase: 'Edge Cookie Settings',
    url: 'https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09',
  },
  {
    phrase: 'Safari Cookie Settings',
    url: 'https://support.apple.com/en-gb/guide/safari/sfri11471/mac',
  },
  {
    phrase: 'Firefox Cookie Settings',
    url: 'https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US',
  },
  { phrase: 'Chrome Cookie Settings', url: 'https://support.google.com/chrome/answer/95647' },
];
