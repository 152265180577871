import LazyLoad from 'react-lazyload';

import { Box, Typography } from '@mui/material';

import ContentBox from 'components/common/ContentBox';
import GradientBox from 'components/common/GradientBox';

// import { FourthGradientBox } from 'components/gradient-boxes/ForServePage';
import EnterprisesImg from 'assets/serve/enterprises.png';

const Enterprises = () => {
  return (
    <GradientBox id="Enterprises">
      <ContentBox
        sx={{
          maxWidth: '1308px',
          display: 'flex',
          flexDirection: { xs: 'column-reverse', sm: 'row' },
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          padding: {
            xs: '72px 16px 36px 16px',
            sm: '72px 20px 36px 20px',
            md: '100px 24px 100px 24px',
          },
        }}
      >
        {/*<FourthGradientBox />*/}

        <Box
          sx={{
            maxWidth: {
              xs: '342px',
              sm: '357px',
              md: '643px',
            },
            mt: { xs: '36px', sm: 0 },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '30px', sm: '40px', md: '52px' },
              fontWeight: { xs: 700, md: 800 },
              lineHeight: { xs: '36px', sm: '48px', md: '62.4px' },
              color: '#1D3061',
            }}
          >
            Enterprises
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '14px', md: '16px' },
              fontWeight: { xs: 400 },
              lineHeight: { xs: '21px', md: '24px' },
              mt: '16px',
            }}
          >
            For enterprises, we offer comprehensive solutions that empower them to streamline their
            financial operations and drive growth.
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '14px', md: '16px' },
              fontWeight: { xs: 400 },
              lineHeight: { xs: '21px', md: '24px' },
              mt: { xs: '4px', md: '16px' },
            }}
          >
            Our methodology encompasses in-depth analysis, strategic consulting, meticulous
            identification of business requirements, seamless setup, successful launch, and ongoing
            support.
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '14px', md: '16px' },
              fontWeight: { xs: 400 },
              lineHeight: { xs: '21px', md: '24px' },
              mt: { xs: '4px', md: '16px' },
            }}
          >
            By leveraging our expertise and resources, enterprises can optimize their financial
            processes and achieve sustainable success.
          </Typography>
        </Box>

        <Box flex={1} display="flex" justifyContent="center">
          <LazyLoad height={400} offset={100} once>
            <Box
              sx={{
                maxWidth: { xs: '343px', sm: '351px', md: '553px' },
                minHeight: { xs: '394px', sm: '403px', md: '636px' },
              }}
              component="img"
              src={EnterprisesImg}
              height="auto"
              alt="Enterprises"
            />
          </LazyLoad>
        </Box>
      </ContentBox>
    </GradientBox>
  );
};

export default Enterprises;
