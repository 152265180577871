import {
  FifthGradientBox,
  FirstGradientBox,
  FourthGradientBox,
  SecondGradientBox,
  SixthGradientBox,
  ThirdGradientBox,
} from 'components/gradient-boxes/ForFeaturePage';

import FifthImg from 'assets/features/mobile/fifthImg.png';
import FirstImg from 'assets/features/mobile/firstImg.png';
import FourthImg from 'assets/features/mobile/fourthImg.png';
import SecondImg from 'assets/features/mobile/secondImg.png';
import SixthImg from 'assets/features/mobile/sixthImg.png';
import ThirdImg from 'assets/features/mobile/thirdImg.png';
import TriangleDownLeft from 'assets/features/triangles/triangleDownLeft.png';
import TriangleDownRight from 'assets/features/triangles/triangleDownRight.png';
import TriangleUpLeft from 'assets/features/triangles/triangleUpLeft.png';
import TriagleUpRight from 'assets/features/triangles/triangleUpRight.png';

export const headerInfoForFeaturesPages = {
  info: 'Bring the full power of Clever Wallet to your smartphone. Designed for both iOS and Android platforms, our mobile application mirrors the sophisticated functionalities of our web version, providing a seamless user experience on the go.',
  title: 'Mobile Wallet',
  details:
    'Your cell phone: The Swiss Army knife of the fintech world - always with you, always functional',
};

export const contentInfoForFeaturesPages = [
  {
    title: 'Universal Compatibility',
    description: [
      'Ready for publication in the iOS App Store and Google Play Store crossplatform flutter solution, ensuring your financial tools are accessible on the most popular mobile platforms.',
    ],
    image: FirstImg,
    imageWidth: { xs: '165px', sm: '237px', md: '420px' },
    imageHeight: { xs: '254px', sm: '336px', md: '648px' },
    gradient: (
      <>
        <FirstGradientBox />
        <SecondGradientBox />
      </>
    ),
    triangle: TriagleUpRight,
  },
  {
    title: 'White Label Solution',
    description: [
      'Launch under your brand with our white-label app, fully customizable to reflect your company`s identity and customer needs.',
    ],
    image: SecondImg,
    imageWidth: { xs: '169px', sm: '238px', md: '460px' },
    imageHeight: { xs: '253px', sm: '355px', md: '687px' },
    triangle: TriangleDownLeft,
  },
  {
    title: 'Full Feature Integration',
    description: [
      'All the core features of our web-based platform, including multicurrency management, invoicing, payments, and transfers, are integrated into the mobile experience as SDK. or for integrating all functionality as SDK into existing mobile apps.',
    ],
    image: ThirdImg,
    imageWidth: { xs: '177px', sm: '238px', md: '510px' },
    imageHeight: { xs: '254px', sm: '341px', md: '729px' },
    gradient: <ThirdGradientBox />,
    triangle: TriangleDownRight,
  },
  {
    title: 'Enhanced Security',
    description: [
      'Equipped with the latest security technologies to protect your transactions and sensitive information, ensuring peace of mind for you and your users.',
    ],
    image: FourthImg,
    imageWidth: { xs: '183px', sm: '237px', md: '429px' },
    imageHeight: { xs: '254px', sm: '329px', md: '597px' },
    gradient: (
      <>
        <FourthGradientBox />
        <FifthGradientBox />
      </>
    ),
    triangle: TriangleUpLeft,
  },
  {
    title: 'User-Friendly Interface',
    description: [
      'Designed with user experience in mind, our Mobile Wallet offers intuitive navigation and a clean interface, making financial management straightforward and hassle-free.',
    ],
    image: FifthImg,
    imageWidth: { xs: '182px', sm: '237px', md: '480px' },
    imageHeight: { xs: '254px', sm: '332px', md: '674px' },
    triangle: TriagleUpRight,
  },
  {
    title: 'Real-Time Notifications',
    description: [
      'Stay updated with real-time alerts on transactions, wallet changes, and security updates directly through the mobile application.',
    ],
    image: SixthImg,
    imageWidth: { xs: '121px', sm: '158px', md: '291px' },
    imageHeight: { xs: '258px', sm: '337px', md: '627px' },
    gradient: <SixthGradientBox />,
    triangle: TriangleDownLeft,
  },
];
