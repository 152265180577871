import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export const gsapHandlerCard = (
  goal: string,
  trigger: string,
  xPosition: string,
  start: string = '0 650px',
  end: string = '800 650px'
) => {
  gsap.to(goal, {
    scrollTrigger: {
      trigger: trigger,
      start: start,
      end: end,
      toggleActions: 'play reset play reset',
      scrub: false,
    },
    x: xPosition,
    display: 'block',
    duration: 0.5,
    opacity: 1,
    ease: 'back',
  });
};

export const gsapHandlerText = (
  goal: string,
  trigger: string,
  start: string = '30 650px',
  end: string = '800 650px'
) => {
  gsap.to(goal, {
    scrollTrigger: {
      trigger: trigger,
      start: start,
      end: end,
      toggleActions: 'play reverse play reverse',
      scrub: false,
    },
    opacity: 1,
    duration: 1,
  });
};
