import React, { FC, useEffect } from 'react';
import { Box, IconButton, Link as MuiLink, Menu, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link, NavLink, useLocation } from 'react-router-dom';
import styles from '../AppBar/AppBar.module.css';
import { scrollHandler } from '../../../utils/scrollHandler';
import Button from '../Button';
import { pages } from '../AppBar';
import { theme } from '../../../theme';
import { ReactComponent as Arrow } from 'assets/appBar/arrowInBtn.svg';
import { ReactComponent as In } from 'assets/appBar/inBlack.svg';

interface IProps{
  handleClick:(event: React.MouseEvent<HTMLButtonElement>)=>void
  handleClose: ()=>void
  isBackground:boolean
  anchorEl: HTMLElement | null
}
export const PopupBurger: FC<IProps> = ({handleClick,handleClose,isBackground,anchorEl}) => {
  const location = useLocation();
  const currentPage = location.pathname;
  const open = Boolean(anchorEl);
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'auto';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);
  return (
    <>
      <IconButton
        edge="end"
        size="large"
        color="primary"
        aria-label="menu"
        onClick={handleClick}
      >
        <MenuIcon sx={{ width: '36px', height: '36px' }} />
      </IconButton>
      <Menu
        disableScrollLock={true}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          '& .MuiPaper-root': { borderRadius: '20px', mt: '-70px', ml: '5px' },
          '& .MuiList-root': {
            padding: 0,
          },
        }}
      >
        <Box
          onClick={handleClose}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: '331px',
            alignItems: 'start',
            p: '20px 28px',
            boxSizing: 'border-box',
            gap: '38px',
          }}
        >
          <IconButton
            edge="end"
            size="large"
            color="primary"
            aria-label="menu"
            onClick={handleClose}
            sx={{ alignSelf: 'end', width: '48px', height: '48px' }}
          >
            <CloseIcon sx={{ width: '48px', height: '48px', m: 5 }} />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              gap: '24px',
            }}
          >
            {pages.map((page) => (
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? '#5260FE' : theme.palette.text.primary,
                  borderBottom: isActive ? '3px #5260FE solid' : '',
                  padding: '2px 8px',
                  fontSize: '14px',
                  fontWeight: 700,
                  lineHeight: '21px',
                })}
                className={`${styles.nav_link} ${
                  isBackground
                    ? styles.all
                    : currentPage === '/' ||
                    currentPage === '/about' ||
                    currentPage === '/faq' ||
                    currentPage === '/privacy-policy' ||
                    currentPage === '/terms-conditions' ||
                    currentPage === '/cookies-settings'
                      ? styles.all
                      : styles.home
                }`}
                key={page.text}
                to={page.link}
                onClick={(e) => {
                  scrollHandler(e, page.link);
                }}
              >
                {page.text}
              </NavLink>
            ))}
          </Box>
          <Button
            variant="contained"
            color="primary"
            sx={{
              width: '178px',
              height: '48px',
              border: `1px ${theme.palette.primary.main} solid`,
              borderRadius: '15px',
              padding: '12px 20px 12px 20px',
            }}
            onClick={(e) => {
              scrollHandler(e, 'demo');
            }}
          >
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'center',
              }}
            >
              Request Demo
            </Typography>
            <Arrow />
          </Button>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              gap: '24px',
            }}
          >
            <Link
              to="/privacy-policy"
              style={{
                color: theme.palette.text.primary,
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '21px',
              }}
            >
              Privacy Policy
            </Link>
            <Link
              to="/terms-conditions"
              style={{
                color: theme.palette.text.primary,
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '21px',
              }}
            >
              Terms of Service
            </Link>
            <Link
              to="/cookies-settings"
              style={{
                color: theme.palette.text.primary,
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '21px',
              }}
            >
              Cookies Settings
            </Link>
          </Box>
          <Box sx={{ display: 'flex', gap: '12px' }}>
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '18px',
                textAlign: 'left',
                color: theme.palette.text.primary,
              }}
            >
              © 2024 Clever Wallet. All rights reserved
            </Typography>
            <MuiLink
              href="https://www.linkedin.com/showcase/clever-wallet"
              underline="none"
              sx={{ alignSelf: { xs: 'start', sm: 'end' } }}
            >
              <In width={18} height={18} />
            </MuiLink>
          </Box>
        </Box>
      </Menu>
    </>
  );
};
