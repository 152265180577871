export const startupsMethodologiesInfo = [
  {
    id: '1',
    title: 'Consulting and strategic planning',
    text: 'Providing advice on strategic development, defining the business model and developing long-term goals.'
  },
  {
    id: '2',
    title: 'Adaptation and customization',
    text: 'Adapt and customize our solutions to meet the unique needs and goals of the Startup, including MVP (minimum viable product) development and testing.'
  },
  {
    id: '3',
    title: 'Launch and Support ',
    text: 'Help launch and market the product and provide technical and strategic support throughout all stages of development.'
  },
]

export const enterprisesMethodologiesInfo = [
  {
    id: '1',
    title: 'Analytics',
    text: 'Conducting comprehensive analysis of current financial processes and operations of an enterprise to identify bottlenecks and potential opportunities for optimization.'
  },
  {
    id: '2',
    title: 'Consulting ',
    text: 'Providing strategic advice to optimize financial processes, improve efficiency and reduce costs.'
  },
  {
    id: '3',
    title: 'Identifying Business Needs',
    text: 'Interacting with business management to determine their specific financial goals and needs.'
  },
  {
    id: '4',
    title: 'Customization',
    text: 'Customizing our solutions and integrating with existing enterprise systems for optimal performance and compliance.'
  },
  {
    id: '5',
    title: 'Launch',
    text: 'Prepare and launch new financial solutions with minimal disruption to current business operations.'
  },
  {
    id: '6',
    title: 'Support',
    text: 'Providing ongoing technical and strategic support, monitoring results and making adjustments to the strategy as needed.'
  },
]