import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';

import { MainLayout } from 'components/main-layout';

import { CookiesSettingsPage } from './cookies-settings';
import { Error } from './error';
import { FeaturesPage } from './features';
import { CryptoTab } from './features/CryptoTab';
import { InvoicingTab } from './features/InvoicingTab';
import { MobileWalletTab } from './features/MobileWalletTab';
import { MulticurrencyTab } from './features/MulticurrencyTab';
import { PaymentTab } from './features/PaymentTab';
import { HomePage } from './home';
import { NotFound } from './not-found';
import { PortfolioPage } from './portfolio';
import { PrivacyPolicyPage } from './privacy-policy';
import Root from './root';
import { ServePage } from './serve';
import { TermsConditionsPage } from './terms-conditions';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />} errorElement={<Error />}>
      <Route element={<MainLayout />}>
        <Route index element={<HomePage />} />
        <Route path="features" element={<FeaturesPage />}>
          <Route index element={<Navigate to="multicurrency" replace />} />
          <Route path="multicurrency" element={<MulticurrencyTab />} />
          <Route path="mobile-wallet" element={<MobileWalletTab />} />
          <Route path="invoicing" element={<InvoicingTab />} />
          <Route path="payment" element={<PaymentTab />} />
          <Route path="crypto" element={<CryptoTab />} />
        </Route>
        <Route path="serve" element={<ServePage />} />
        <Route path="portfolio" element={<PortfolioPage />} />
        <Route path="about" element={<Navigate to='/#about' replace/>} />
        <Route path="faq" element={<Navigate to='/#faq' replace/>}  />
        <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="terms-conditions" element={<TermsConditionsPage />} />
        <Route path="cookies-settings" element={<CookiesSettingsPage />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Route>
  )
);

export default router;
