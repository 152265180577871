import { useMediaSize } from 'hooks/useDeviceDetector';
import React from 'react';
import { scrollHandler } from 'utils/scrollHandler';

import { Box, Typography, useTheme } from '@mui/material';

import BackgroundAnimation from 'components/common/Balls';
import Button from 'components/common/Button';
import { GroupedPhoto } from 'components/home/first/ GroupedPhoto/GroupedPhoto';

export const HomeFirst = () => {
  const theme = useTheme();
  const { isPhone, isMobileDevice } = useMediaSize();
  return (
    <Box
      className="home_first_section"
      sx={{
        padding: {
          xs: '68px 16px 72px 16px',
          sm: '68px 20px 72px 20px',
          md: '68px 24px 100px 24px',
        },
        position: 'relative',
        zIndex: 1,
      }}
    >
      <BackgroundAnimation speed={isPhone ? 100 : 150} blur={isPhone ? 90 : 190} diameter={isPhone ? 250 : 500} countOfBalls={isPhone ? 3 : 5}  zIndex={-3} />
      <Box
        sx={{
          minHeight: { xs: '724px', sm: '669px', md: '880px' },
          maxWidth: '1308px',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          flexWrap: 'nowrap',
          position: 'relative',
          margin: 'auto',
        }}
      >
        {/*<Box*/}
        {/*  sx={{*/}
        {/*    position: 'absolute',*/}
        {/*    zIndex: '-9',*/}
        {/*    borderRadius: '50%',*/}
        {/*    width: '1262px',*/}
        {/*    height: '1262px',*/}
        {/*    top: '-608px',*/}
        {/*    left: '-518px',*/}
        {/*    gap: '0px',*/}
        {/*    opacity: '0px',*/}
        {/*    angle: '-90 deg',*/}
        {/*    background:*/}
        {/*      'radial-gradient(circle,rgba(82, 96, 254, 0.5) 0%, rgba(82, 96, 254, 0) 50%)',*/}
        {/*  }}*/}
        {/*/>*/}
        {/*<Box*/}
        {/*  sx={{*/}
        {/*    position: 'absolute',*/}
        {/*    zIndex: '-9',*/}
        {/*    width: '1028px',*/}
        {/*    height: '1028px',*/}
        {/*    top: '81px',*/}
        {/*    left: { xs: '-50px', md: '527px' },*/}
        {/*    gap: '0px',*/}
        {/*    opacity: '0px',*/}
        {/*    angle: '-90 deg',*/}
        {/*    background:*/}
        {/*      'radial-gradient(circle,rgba(200, 113, 253, 0.5) 0%, rgba(200, 113, 253, 0) 70%)',*/}
        {/*  }}*/}
        {/*/>*/}
        {/*<Box*/}
        {/*  sx={{*/}
        {/*    position: 'absolute',*/}
        {/*    zIndex: '-8',*/}
        {/*    width: '728px',*/}
        {/*    height: '728px',*/}
        {/*    top: '22px',*/}
        {/*    left: { xs: '211px', md: '711px' },*/}
        {/*    gap: '0px',*/}
        {/*    opacity: '0px',*/}
        {/*    angle: '-90 deg',*/}
        {/*    background:*/}
        {/*      'radial-gradient(circle,rgba(254, 124, 82, 0.45) 0%, rgba(254, 124, 82, 0) 70%)',*/}
        {/*  }}*/}
        {/*/>*/}
        <Box sx={{ mt: { xs: '76px', sm: '53px', md: '68px' }, width: { xs: '100%', md: '57%' } }}>
          <Typography
            className="home_first_title"
            sx={{
              fontSize: { xs: '40px', sm: '48px', md: '56px' },
              fontWeight: { xs: 700, sm: 800, md: 900 },
              lineHeight: { xs: '48px', sm: '57.6px', md: '67.2px' },
              textAlign: 'left',
              color: theme.palette.text.secondary,
            }}
          >
            Clever Wallet
          </Typography>
          <Typography
            className="home_first_subtitle"
            sx={{
              fontSize: { xs: '24px', sm: '32px' },
              fontWeight: { xs: 700 },
              lineHeight: { xs: '33.6px', sm: '41.6px' },
              textAlign: 'left',
            }}
          >
            is a service for creating a
            <br />
            multifunctional fintech platforms
          </Typography>
          <Typography
            className="home_first_sub_subtitle"
            sx={{
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '27px',
              textAlign: 'left',
              marginTop: '16px',
              width: '78%',
              display: { xs: 'none', md: 'block' },
            }}
          >
            Functional as a Swiss Army Knife, customizable as Lego:
            <br />
            Every tool you need, any configuration you want!
          </Typography>
          {!isMobileDevice && (
            <Button
              className="home_first_button"
              variant="contained"
              color="primary"
              sx={{ width: '300px', minHeight: '70px', borderRadius: '20px', mt: '64px' }}
              onClick={(e) => {
                scrollHandler(e, 'contact');
              }}
            >
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 600,
                  lineHeight: '30px',
                  textAlign: 'center',
                }}
              >
                Let’s start
              </Typography>
            </Button>
          )}
        </Box>
        <Box
          sx={{
            width: { xs: '100%', md: '518px' },
            position: 'relative',
            display: 'flex',
            alignItems: { xs: 'center', sm: 'start' },
            justifyContent: { xs: 'center', sm: 'start' },
            flexDirection: { xs: 'column', sm: 'row-reverse' },
            gap: { xs: '20px', sm: '150px' },
            mt: { xs: '140px', md: '0' },
          }}
        >
          <GroupedPhoto />
          {isMobileDevice && (
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: { xs: '100%', sm: '261px' },
                minHeight: '60px',
                borderRadius: '20px',
                mb: '100px',
              }}
              onClick={(e) => {
                scrollHandler(e, 'contact');
              }}
            >
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 600,
                  lineHeight: '30px',
                  textAlign: 'center',
                }}
              >
                Let’s start
              </Typography>
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};
