import { backgroundGradient } from 'consts/style';

import { Box, Link, Typography, useTheme } from '@mui/material';

import Button from 'components/common/Button';

export const DemoTest = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        minHeight: { xs: '365px', sm: '276px', md: '376px' },
        padding: {
          xs: '36px 16px 72px 16px',
          sm: '36px 20px 72px 20px',
          md: '50px 24px 100px 24px',
        },
        boxSizing: 'border-box',
        background: theme.palette.background.default,
      }}
      id="demo"
    >
      <Box
        sx={{
          minHeight: '226px',
          display: 'flex',
          maxWidth: '1307px',
          flexDirection: { xs: 'column', sm: 'row' },
          margin: 'auto',
          borderRadius: '20px',
          padding: { xs: '24px', md: '50px 60px 50px 60px' },
          boxSizing: 'border-box',
          background: backgroundGradient,
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography
            sx={{
              fontSize: { xs: '30px', sm: '32px', md: '52px' },
              fontWeight: { xs: 700, md: 800 },
              lineHeight: { xs: '36px', sm: '41.6px', md: '62.4px' },
              textAlign: 'left',
              color: '#FFFFFF',
            }}
          >
            The Clever Wallet:
            <br />A Demo Test
          </Typography>
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography
            sx={{
              fontSize: { xs: '16px', md: '18px' },
              fontWeight: 400,
              lineHeight: { xs: '24px', md: '27px' },
              textAlign: 'left',
              color: 'rgba(255, 255, 255, 1)',
            }}
          >
            Experience the power of our financial software development kits and APIs
          </Typography>
          <Box sx={{ mt: '24px', display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
            <Link href="https://calendly.com/cleverwallet/30min" target="_blank" underline="none">
              <Button
                variant="contained"
                sx={{
                  borderRadius: '15px',
                  backgroundColor: theme.palette.background.default,
                  border: `1px ${theme.palette.background.default} solid`,
                  padding: { xs: '12px 16px 12px 16px', sm: '12px 20px 12px 20px' },
                  boxSizing: 'border-box',
                  '&:hover': { backgroundColor: theme.palette.grey[100] },
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: '14px', sm: '16px' },
                    fontWeight: 600,
                    lineHeight: { xs: '21px', sm: '24px' },
                    textAlign: 'center',
                    color: theme.palette.primary.main,
                  }}
                >
                  Schedule a Call
                </Typography>
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
