import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'theme';

interface IProps {
  action: 'mailto' | 'tel';
  image: React.ReactNode;
  title: string;
}

export const LinkToMailPhone: FC<IProps> = ({ action, image,title }) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Link
      to={`${action}:${title}`}
      style={{
        textDecoration: 'none',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'left',
        display: 'flex',
        flexWrap: 'nowrap',
        gap: '16px',
        alignItems: 'center',
        marginTop: '16px',
        color: isHovered ? theme.palette.text.secondary : theme.palette.text.primary,
        transition: 'color 0.1s',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {image}
      {title}
    </Link>
  );
};
